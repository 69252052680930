import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const sources = this.element.querySelectorAll('[data-copy-fields-source]')

    for (let source of sources) {
      if (source.dataset.copyFieldsCopyOnConnect == "true") {
        const targets = this.element.querySelectorAll('[data-copy-fields-target="' + source.dataset.copyFieldsSource + '"]')

        for (let target of targets) {
          if (target.value == null || target.value == "") {
            target.value = this._getDateFromValue(source.value)
            this._modify_values(target)
            target.dispatchEvent(new CustomEvent('change'));
            this.dispatch("paste", { target: target })
          }
        }
      }
    }
  }

  copy(event) {
    const source = event.target
    const targets = this.element.querySelectorAll('[data-copy-fields-target="' + source.dataset.copyFieldsSource + '"]')

    for (let target of targets) {
      if (target.value == null || target.value == "") {
        target.value = this._getDateFromValue(source.value)
        this._modify_values(target)
        target.dispatchEvent(new CustomEvent('change'));
        this.dispatch("paste", { target: target })
      }
    }
  }

  rewrite(event) {
    const source = event.target
    const targets = this.element.querySelectorAll('[data-copy-fields-target="' + source.dataset.copyFieldsSource + '"]')

    for (let target of targets) {
      target.value = this._getDateFromValue(source.value)
      this._modify_values(target)
      target.dispatchEvent(new CustomEvent('change'));
      this.dispatch("paste", { target: target })
    }
  }

  _modify_values(target) {
    if (target.dataset.copyFieldsAddUnit != null) {
      let date = new Date(target.value)
      if (target.dataset.copyFieldsAddUnit == "hour") {
        let old_hours = date.getHours()
        let new_hours = old_hours + parseInt(target.dataset.copyFieldsAddValue)
        date.setHours(new_hours)
          // Here's why: https://stackoverflow.com/questions/28760254/assign-javascript-date-to-html5-datetime-local-input
        const dateTimeLocalValue = (new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);
        target.value = dateTimeLocalValue
      }
    }
  }

  _getDateFromValue(value) {
    let date = new Date(value)
    const dateTimeLocalValue = (new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);
    return dateTimeLocalValue
  }

}
