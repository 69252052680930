import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static targets = ["numberField", "bankCodeField"]

  split(event) {
    event.preventDefault()

    if (event.data.includes("/")) {
      let number = event.data.split("/")
      this.numberFieldTarget.value = number[0]
      this.bankCodeFieldTarget.value = number[1]
    }
  }
}
