import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  elementTargetConnected() {
    this.scroll()
  }

  scroll() {
    this.elementTarget.scrollIntoView()
  }

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }


}
