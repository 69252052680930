import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (!this._getCookie("cookieAccepted")) {
      this.element.classList.remove("d-none");
    }
  }

  consent() {
    this._setCookie("cookieAccepted", "true", 365); // Cookie will expire in 365 days
    this.element.classList.add("d-none");
  }

  deconsent() {
    this._removeCookie("cookieAccepted");
    this.element.classList.remove("d-none");
  }

  _setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  _getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  _removeCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }
}
