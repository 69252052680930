import { Controller } from "../../node_modules/@hotwired/stimulus"
import { Fireworks } from '../../node_modules/fireworks-js'

export default class extends Controller {

	connect() {
		const fireworks = new Fireworks(this.element, { acceleration: 1, mouse: { click: true, move: false, max: 3 } })
		fireworks.start()

		// const container = document.querySelector('.fireworks-container')
		// const fireworks = new Fireworks(container, { /* options */ })

		// fireworks.start()
		// fireworks.pause()
		// fireworks.clear()

		// // stop and clear fireworks
		// fireworks.stop()

		// // after initialization you can change the fireworks parameters
		// fireworks.setOptions({ delay: { min: 10, max: 15 }})
	}

}
