if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/serviceworker.js', {
        scope: '/'
    }).then(function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
        window.serviceWorkerRegistration = registration;
    }, function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
    });
  })
}

// var deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  // Prevents the default mini-infobar or install dialog from appearing on mobile
  e.preventDefault();
  console.log("can install!")
  // Save the event because you'll need to trigger it later.
  globalThis.deferredPrompt = e;
  // Show your customized install prompt for your PWA
  // Your own UI doesn't have to be a single element, you
  // can have buttons in different locations, or wait to prompt
  // as part of a critical journey.
  // showInAppInstallPromotion();
});

