import { Controller } from "../../node_modules/@hotwired/stimulus";
// import selectize from '../../node_modules/@selectize/selectize';
import select2 from '../../node_modules/select2';
window.select2 = select2();

export default class extends Controller {
  static targets = ["select"]
  static values = {
    placeholder: { type: String, default: '' },
    theme: { type: String, default: 'default' }
  }

  // Since jQuery3.6, autofocus is not working
  // fix gotten from https://stackoverflow.com/questions/68030101/why-is-jquery-select2-autofocus-not-working-and-how-do-i-fix-it
  // but! this doesn't work correctly if there's more than one select2 input on page
  // connect() {
  //   $(document).on('select2:open', () => {
  //     document.querySelector('.select2-search__field').focus();
  //   });
  // }

  selectTargetConnected() {
    $(this.selectTarget).select2({
      placeholder: this.placeholderValue,
      theme: this.themeValue
    });
    $(this.selectTarget).next(".select2")[0].dataset.action = "click->enable-select2#focus"
  }

  rerender() {
    $(this.selectTarget).select2();
    $(this.selectTarget).next(".select2")[0].dataset.action = "click->enable-select2#focus"
  }

  focus() {
    // Gets the search input of the opened select2
    let searchInput = document.querySelector('.select2-container--open .select2-search__field');
    // If exists
    if (searchInput) {
      searchInput.focus(); // focus
    }
  }

}
