// source code: https://github.com/stimulus-components/stimulus-clipboard/blob/master/src/index.ts
import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
  static values = {
    successDuration: {
      type: Number,
      default: 3500
    },
    successMessage: {
      type: String,
      default: "odkaz zkopírován"
    }
  }

  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = `<i class="bi bi-clipboard-check"></i> ${this.successMessageValue}`
    this.buttonTarget.classList.remove("btn-outline-primary")
    this.buttonTarget.classList.add("btn-outline-success")

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent;
      this.buttonTarget.classList.add("btn-outline-primary")
      this.buttonTarget.classList.remove("btn-outline-success")
    }, this.successDurationValue)
  }
}
