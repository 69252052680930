import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {
    static targets = ["item", "button"]

    show() {
        const event = new CustomEvent("revelation-shown")
        window.dispatchEvent(event)

        super.show()
        this.buttonTarget.dataset.action = "click->reveal#hide"
    }

    hide() {
        super.hide()
        this.buttonTarget.dataset.action = "click->reveal#show"
    }
}
