import { Controller } from "@hotwired/stimulus"

// https://github.com/NejRemeslnici/nere/wiki/Vybran%C3%A9-Stimulus-kontrolery#debugturbo-controller
export default class extends Controller {
  static documentEvents = ["before-visit", "visit",
    "before-cache", "before-render", "before-stream-render", "render", "load", "reload"
  ]
  static elementEvents = ["click", "before-fetch-request", "before-fetch-response",
    "submit-start", "submit-end",
    "before-frame-render", "frame-render", "frame-load", "fetch-request-error", "frame-missing"
  ]
  connect() {
    this.constructor.documentEvents.concat(this.constructor.elementEvents).forEach(event => {
      document.addEventListener(`turbo:${event}`, this._log)
    })
  }

  disconnect() {
    this.constructor.documentEvents.concat(this.constructor.elementEvents).forEach(event => {
      this.element.removeEventListener(`turbo:${event}`, this._log)
    })
  }

  _log(event) {
    console.debug(`Turbo Event: ${event.type}`, event)
  }
}
