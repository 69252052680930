import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, method: String }

  call(event) {
    event.preventDefault();

    const url = this.urlValue;
    const method = this.methodValue || 'POST'; // use provided method or default to POST

    fetch(url, {
        method: method,
        // headers: { 'Content-Type': 'application/json' }
      })
      // .then(response => response.json())
      // .then(data => {
      //   // Handle the response data
      //   console.log(data);
      // })
      // .catch((error) => {
      //   console.error('Error:', error);
      // });
  }
}
