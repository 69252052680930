import { Controller } from "../../node_modules/@hotwired/stimulus"
import geocomplete from "../../node_modules/geocomplete"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    if (this.hasSelectTarget) {
      $(this.selectTarget).geocomplete({
        types: ['geocode', 'establishment'],
        details: ".details",
        detailsAttribute: "data-geo"
      });
    }
  }
}
