import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static targets = ["area"]

  connect() {
    let url = ""
    let text = this.areaTarget.innerHTML
    text = text.trim()

    try {
      url = new URL(text);
    } catch (_) {
      // pass
    }

    if (url.protocol === "https:") {
      let link = document.createElement("a")
      link.href = text
      link.innerHTML = text
      link.target = "_blank"

      this.areaTarget.innerHTML = ""
      this.areaTarget.appendChild(link)
    }

  }

}
