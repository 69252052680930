import { Controller } from "../../node_modules/@hotwired/stimulus"
import Cropper from '../../node_modules/cropperjs';

export default class extends Controller {
  static values = {
    "uploadPath": String,
    "submittingInfo": { type: String, default: "soubor se nahrává.." }
  }
  static targets = ["input", "image"]

  show(event) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();

    this.imageTarget.title = selectedFile.name;

    const controller = this

    reader.onload = function(event) {
      controller.imageTarget.src = event.target.result;
      controller.cropper = new Cropper(controller.imageTarget, {
        aspectRatio: 1 / 1,
        viewMode: 1,
        dragMode: 'move',
        cropBoxMovable: false,
        cropBoxResizable: false,
        autoCropArea: 0.9
      });
    };

    reader.readAsDataURL(selectedFile);
  }

  crop(event) {
    let originalValue = event.target.innerHTML
    event.target.innerHTML = this.submittingInfoValue
    event.target.disabled = true

    this.cropper.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();

      // Pass the image file name as the third parameter if necessary.
      formData.append('croppedImage', blob);

      // Use `jQuery.ajax` method for example
      fetch(this.uploadPathValue, {
          method: 'POST',
          body: formData,
          processData: false,
          contentType: false
        })
        .then(response => {
          if (response.ok) {
            window.location.reload();
            console.log('Upload success');
          } else {
            console.log('Upload error');
          }

          event.target.innerHTML = originalValue
          event.target.disabled = false
        })
        .catch(error => {
          console.log('Upload error:', error);
        });

    } /*, 'image/png' */ );
  }

}
