// For some reason, importing jquery directly here is not working.
// This hack is from here: https://github.com/excid3/esbuild-rails/issues/4
import "./jquery";

import 'bootstrap';
import 'bootstrap-table';
// $.extend($.fn.bootstrapTable.defaults, $.fn.bootstrapTable.locales['cs-CZ'])

// add service worker and other PWA things
import "./pwa";

// Start Sentry to log FE errors
import "./sentry";


// Use morphdom for Turbo Frames
import morphdom from "morphdom"
addEventListener("turbo:before-frame-render", (event) => {
  // console.log(event.target)
  if (event.target.querySelector("turbo-frame") === null) {
    // console.log("morphing")
    event.detail.render = (currentElement, newElement) => {
      morphdom(currentElement, newElement, { childrenOnly: true })
    }
  }
})

// Stimulus
import { Application } from "@hotwired/stimulus"

window.Stimulus = Application.start()
  // window.Stimulus.debug = true

import BankAccountController from "../controllers/bank_account_controller.js"
window.Stimulus.register('bank-account', BankAccountController)

// Basic copy to clipboard functionality
import ClipboardController from "../controllers/clipboard_controller.js"
window.Stimulus.register('clipboard', ClipboardController)

import ClickerController from "../controllers/clicker_controller.js"
window.Stimulus.register('clicker', ClickerController)

// Manages jquery-connections
import ConnectionController from "../controllers/connection_controller.js"
window.Stimulus.register('connection', ConnectionController)
import ConnectionsController from "../controllers/connections_controller.js"
window.Stimulus.register('connections', ConnectionsController)

// Manages cookie consent
import CookieConsentController from "../controllers/cookie_consent_controller.js"
window.Stimulus.register('cookie-consent', CookieConsentController)

// Used mostly for autofilling form fields
import CopyFieldsController from "../controllers/copy_fields_controller.js"
window.Stimulus.register('copy-fields', CopyFieldsController)

import CropperController from "../controllers/cropper_controller.js"
window.Stimulus.register('cropper', CropperController)

// Custom events dispatcher mostly for communication between controllers
import CustomEventsController from "../controllers/custom_events_controller.js"
window.Stimulus.register('custom-events', CustomEventsController)

// DEV: show turbo events
import DebugTurboController from "../controllers/debug_turbo_controller.js"
window.Stimulus.register('debug-turbo', DebugTurboController)

import DropzoneController from "../controllers/dropzone_controller.js"
window.Stimulus.register('dropzone', DropzoneController)

import EnableEditorController from "../controllers/enable_editor_controller.js"
window.Stimulus.register('enable-editor', EnableEditorController)

import EnableGeocompleteController from "../controllers/enable_geocomplete_controller.js"
window.Stimulus.register('enable-geocomplete', EnableGeocompleteController)

import ImagepickerController from "../controllers/imagepicker_controller.js"
window.Stimulus.register('imagepicker', ImagepickerController)

import EnableSelect2Controller from "../controllers/enable_select2_controller.js"
window.Stimulus.register('enable-select2', EnableSelect2Controller)

import EnableTableController from "../controllers/enable_table_controller.js"
window.Stimulus.register('enable-table', EnableTableController)

import FireworksController from "../controllers/fireworks_controller.js"
window.Stimulus.register('fireworks', FireworksController)

import FormController from "../controllers/form_controller.js"
window.Stimulus.register('form', FormController)

import HighligtController from "../controllers/highlight_controller.js"
window.Stimulus.register('highlight', HighligtController)

import MemberRolePickerController from "../controllers/member_role_picker_controller.js"
window.Stimulus.register('member-role-picker', MemberRolePickerController)

import NotificationController from "../controllers/notification_controller.js"
window.Stimulus.register('notification', NotificationController)

import PasswordVisibilityController from "../controllers/password_visibility_controller.js"
window.Stimulus.register('password-visibility', PasswordVisibilityController)

import RevealController from "../controllers/reveal_controller.js"
window.Stimulus.register('reveal', RevealController)

import SboodleFillerController from "../controllers/sboodle_filler_controller.js"
window.Stimulus.register('sboodle-filler', SboodleFillerController)

import ScrollerController from "../controllers/scroller_controller.js"
window.Stimulus.register('scroller', ScrollerController)

import ShowLinkController from "../controllers/show_link_controller.js"
window.Stimulus.register('show-link', ShowLinkController)

// Enables showing date/time picker on input field
import ShowPickerController from "../controllers/show_picker_controller.js"
window.Stimulus.register('show-picker', ShowPickerController)

import VisibilityController from "../controllers/visibility_controller.js"
window.Stimulus.register('visibility', VisibilityController)

import UrlCallController from "../controllers/url_call_controller.js"
window.Stimulus.register('url-call', UrlCallController)


import Sortable from 'stimulus-sortable'
window.Stimulus.register('sortable', Sortable)

// Turbo
// import * as Turbo from "@hotwired/turbo"
