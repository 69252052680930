import { Controller } from "../../node_modules/@hotwired/stimulus"
import connections from "../../node_modules/jquery-connections"

export default class extends Controller {
  // connect() {
  //   this.redrawAllConnections()
  // }

  disconnect() {
    this.removeAllConnections()
  }

  redrawAllConnections() {
    $('connection').connections('update');
  }

  removeAllConnections() {
    $('connection').connections('remove');
  }

}
