import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
	static values = {
		"event": String,
		"dispatch-on-load": { type: Boolean, default: false },
		"delay": { type: Number, default: 0 }
	}

	connect() {
		if (this.dispatchOnLoadValue == true) {
			this.dispatch()
		}
	}

	dispatch() {
		const event = new CustomEvent(this.eventValue)

		if (this.delayValue > 0) {
			setTimeout(() => window.dispatchEvent(event), this.delayValue * 1000)
		} else {
			window.dispatchEvent(event)
		}

	}

}
