import { Controller } from "../../node_modules/@hotwired/stimulus"
import imagepicker from '../../node_modules/image-picker';

export default class extends Controller {
  static targets = ["select", "toggleButton"]
  static values = { showLabels: { type: Boolean, default: false } }

  connect() {
    if (this.hasSelectTarget) {
      $(this.selectTarget).imagepicker({ show_label: true });
    }

    console.log(this.showLabelsValue)

    if (!this.showLabelsValue) {
      this.hideLabels();
    }
  }

  disconnect() {
    if (this.element.querySelector(".image_picker_selector") != null) {
      this.element.querySelector(".image_picker_selector").remove();
    }
  }

  hideLabels() {
    const labels = $(this.selectTarget).data('picker').picker[0].querySelectorAll("li div.thumbnail p");
    labels.forEach(label => {
      label.classList.add("d-none");
    })

    this.toggleButtonTarget.innerText = "zobrazit jména"
    this.toggleButtonTarget.dataset.action = "click->imagepicker#showLabels"
  }

  showLabels() {
    const labels = $(this.selectTarget).data('picker').picker[0].querySelectorAll("li div.thumbnail p");
    labels.forEach(label => {
      label.classList.remove("d-none");
    })
    this.toggleButtonTarget.innerText = "skrýt jména"
    this.toggleButtonTarget.dataset.action = "click->imagepicker#hideLabels"
  }

}
