import { Controller } from "../../node_modules/@hotwired/stimulus"
import connections from "../../node_modules/jquery-connections"

export default class extends Controller {
  static targets = ["connectable"]
  static values = {
    elementType: String,
    toAnchorIn: { default: false, type: Boolean }
  }

  connectableTargetConnected() {
    this._connectChildren()

    // Manage new comment form
    if (this.connectableTarget.dataset.id == 0) {
      this._createConnectionToParent()
    }

    const event = new CustomEvent("comment-connected")
    window.dispatchEvent(event)
  }

  connectableTargetDisconnect() {
    // this.removeConnection()
    const event = new CustomEvent("comment-removed")
    window.dispatchEvent(event)
  }

  // removeConnection() {
  //   // this is not working with turbo, probably because element is deleted before we try to delete
  //   const childId = this.connectableTarget.dataset.id
  //   const parentId = this.connectableTarget.dataset.parentId

  //   $('#comment-' + parentId + ', #comment-' + childId).connections('remove')
  // }

  _connectChildren() {
    for (let i = 0, child; child = this._children()[i]; i++) {
      this._createConnection(this.connectableTarget, child)
    }
  }

  _createConnectionToParent() {
    const parentId = this.connectableTarget.dataset.parentId
    const childId = this.connectableTarget.dataset.id

    $(`#${this.elementTypeValue}-${parentId} .anchor-out, #${this.elementTypeValue}-${childId} .anchor-in`).connections()
  }

  _createConnection(parent, child) {
    if (this.toAnchorInValue === true) {
      $(`#${this.elementTypeValue}-${parent.dataset.id} .anchor-out, #${this.elementTypeValue}-${child.dataset.id} .anchor-in`).connections();
    } else {
      $(`#${this.elementTypeValue}-${parent.dataset.id} .anchor-out, #${this.elementTypeValue}-${child.dataset.id}`).connections();
    }
  }

  _children() {
    return document.querySelectorAll(`[data-parent-id="${this.connectableTarget.dataset.id}"]`)
  }

}
