import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dataField", "form"]

  connect() {
    window.mouseDown = false;
    document.onmousedown = function() {
      window.mouseDown = true;
    }
    document.ontouchstart = function() {
      window.mouseDown = true;
    }
    document.onmouseup = function() {
      window.mouseDown = false;
    }
    document.ontouchend = function() {
      window.mouseDown = false;
    }

    // WIP - if theres another already, it would be rewritten by this
    this.formTarget.querySelector('input[type="submit"]').dataset.action = "click->sboodle-filler#saveValues:prevent"
  }

  toggle(event) {
    let element = event.target
    if (element.dataset.filled == "true") {
      this._empty(element)
    } else {
      this._fill(element)
    }
  }

  toggleIfMousedown(event) {
    let element = event.target
    if (element.dataset.filled == "true") {
      this._emptyIfMousedown(element)
    } else {
      this._fillIfMousedown(element)
    }
  }

  _fill(element) {
    // element.classList.add("bg-primary")
    element.dataset.filled = true
  }

  _fillIfMousedown(element) {
    if (window.mouseDown) {
      this._fill(element)
    }
  }

  _empty(element) {
    // element.classList.remove("bg-primary")
    element.dataset.filled = false
  }


  _emptyIfMousedown(element) {
    if (window.mouseDown) {
      this._empty(element)
    }
  }

  saveValues() {
    // get all divs with data-filled=true value
    let filled = this.element.querySelectorAll("[data-filled=true]")
    filled = Array.from(filled).map(element =>
        ({
          x: element.dataset.x,
          y: element.dataset.y,
          day: element.dataset.day,
          timeStart: element.dataset.timeStart,
          timeEnd: element.dataset.timeEnd
        })
      )
      // sent data
    this.dataFieldTarget.value = JSON.stringify(filled)
    this.formTarget.submit()

  }
}
