import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add("cursor-clickable")
    this.element.dataset.action = "click->clicker#activateLink"
  }

  activateLink(event) {
    if (event.target.dataset.bsToggle == "modal") { return; } // prevent this when clicking modal button
    if (event.target.tagName == "A") { return; } // prevent this when clicking a link

    const first_link = event.target.getElementsByTagName("a")[0];
    first_link.click();
  }

}
