import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static classes = ["end"]

  highlight({ target }) {
    let endClass = this.endClass
    target.classList.add(this.endClass)
    target.addEventListener('animationend', function() {
      target.classList.remove(endClass);
    })
  }

}
