import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
	static targets = ["input"]

	show() {
		this.inputTarget.showPicker()
	}

}
