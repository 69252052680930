import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["wrapper", "field", "icon"]
  }

  connect() {
    this._add_icon()
  }

  _add_icon() {
    var eye_icon = '<span class="input-group-text" data-action="click->password-visibility#toggle"><i class="bi bi-eye" data-password-visibility-target="icon"></i></span>'

    this.wrapperTarget.innerHTML += eye_icon;
  }

  toggle() {
    if (this.fieldTarget.type == "text") {
      this.turnOff()
    } else {
      this.turnOn()
    }

    this.fieldTarget.focus()
  }

  turnOn() {
    this.fieldTarget.setAttribute("type", "text")
    this.iconTarget.classList.remove("bi-eye")
    this.iconTarget.classList.add("bi-eye-slash")
  }

  turnOff() {
    this.fieldTarget.setAttribute("type", "password")
    this.iconTarget.classList.remove("bi-eye-slash")
    this.iconTarget.classList.add("bi-eye")
  }
}
