import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
  static targets = ["memberSelect"]
  static values = { pickerId: String }

  // TODO:
  connect() {
    //    // for member_role check if all are selected and change between selected/deselected accordingly
    //    // also do this on every de/select
    this.sync_buttons()
  }

  sync_buttons() {
    for (let i = 0, button; button = this.#buttons()[i]; i++) {
      this.sync_button(button)
    }
  }

  sync_button(button){
    let member_role_id = button.dataset.memberRoleId
    if (this.#selected_options(member_role_id).length == this.#relevant_options(member_role_id).length) {
      // all are selected
      button.innerHTML = "odebrat " + button.dataset.name
      button.dataset.action = "click->member-role-picker#deselect"
    } else {
      button.innerHTML = "přidat " + button.dataset.name
      button.dataset.action = "click->member-role-picker#select"
    }

    if (this.#relevant_options(member_role_id).length == 0) {
      button.disabled = true
    }
  }


  select(event) {
    var member_role_id = event.params.id

    for (let i = 0, option; option = this.#relevant_options(member_role_id)[i]; i++) {
      option.selected = true;
    }

    $("#" + this.pickerIdValue).data('picker').sync_picker_with_select();

    this.sync_buttons()
  }

  deselect(event) {
    var member_role_id = event.params.id

    for (let i = 0, option; option = this.#relevant_options(member_role_id)[i]; i++) {
      option.selected = false;
    }

    $("#" + this.pickerIdValue).data('picker').sync_picker_with_select();

    this.sync_buttons()
  }

  // #private

  #option_member_role_ids(option){
    let member_role_ids = option.dataset.memberRoleIds.split(" ").map(function(item) {return parseInt(item, 10);})
    return member_role_ids
  }

  #relevant_options(member_role_id) {
    let relevant_options = [];
    member_role_id = parseInt(member_role_id, 10)

    for (let i = 0, option; option = this.memberSelectTarget.options[i]; i++) {
      let member_role_ids = this.#option_member_role_ids(option);

      if (member_role_id == 0 || member_role_ids.includes(member_role_id)) {
        relevant_options.push(option)
      }
    }

    return relevant_options
  }

  #selected_options(member_role_id) {
    let selected_options = [];
    member_role_id = parseInt(member_role_id, 10)

    for (let i = 0, option; option = this.#relevant_options(member_role_id)[i]; i++) {
      if (option.selected == true) {
        selected_options.push(option);
      }
    }

    return selected_options
  }

  #buttons() {
    return this.element.querySelectorAll("[data-member-role-picker-id-param]")
  }

}
