import { Controller } from "../../node_modules/@hotwired/stimulus"

export default class extends Controller {
	static values = {
		title: String,
		body: String
	}

	static targets = ["requestPermission"]

	connect() {
		if ('serviceWorker' in navigator && 'PushManager' in window) {
			console.log("can notify!")
		}

		this.sync()
	}

	sync() {
		// We need the service worker registration to check for a subscription
		navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			// Do we already have a push message subscription?
			serviceWorkerRegistration.pushManager.getSubscription()
				.then((subscription) => {
					if (!subscription) {
						// We aren't subscribed to push, so set UI
						// to allow the user to enable push
						return;
					}

					// Keep your server in sync with the latest subscriptionId
					// sendSubscriptionToServer(subscription);

					// showCurlCommand(subscription);

					// Set your UI to show they have subscribed for
					// push messages
					this.requestPermissionTarget.disabled = true
					this.requestPermissionTarget.innerHTML = "super, už ti můžou chodit notifikace!"

				})
				.catch((err) => {
					console.error(`Error during getSubscription(): ${err}`);
				});
		});
	}

	requestPermission() {
		let sync = this.sync
		Notification.requestPermission().then((result) => {
			if (result === 'granted') {
				console.log("You can now recieve notifications!")
				this._subscribeUserToPush()
			}
			sync()
		});
	}

	_subscribeUserToPush() {
		const subscribeOptions = {
			userVisibleOnly: true,
			applicationServerKey: 'BP5lB7wN9iGavEPHwl6kUEk01FsQteTim8iCpLTBdpHDWlVEhAbZ3Xe1ujFvUE1gIIH4NF3khSkLOMzRyT8AKps'
		};
		window.serviceWorkerRegistration.pushManager.subscribe(subscribeOptions)
			.then((subscription) => {
				console.log(
					'Received PushSubscription: ',
					JSON.stringify(subscription),
				);
				sendSubscriptionToBackEnd(subscription)
				this.sync()
				return subscription;
			});
	}

	// _unsubscribeUserFromPush() {
	// 	window.serviceWorkerRegistration.pushManager.getSubscription()
	// 		.then((subscription) => {
	// 			subscription.unsubscribe()
	// 				.then((successful) => {
	// 					// You've successfully unsubscribed
	// 					console.log("You've successfully unsubscribed")
	// 					this.sync()
	// 					return
	// 				}).catch((e) => {
	// 					// Unsubscribing failed
	// 					console.log("Unsubscribing failed")
	// 					this.sync()
	// 				})
	// 		})
	// }

	updateNotificationBadge() {
		const unreadCount = fetch('/notification_count')

		if (navigator.setAppBadge) {
			if (unreadCount && unreadCount > 0) {
				navigator.setAppBadge(unreadCount);
			} else {
				navigator.clearAppBadge();
			}
		}
	}

}

function sendSubscriptionToBackEnd(subscription) {
	return fetch('/api/push-notification/save-subscription', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(subscription),
		})
		.then(function(response) {
			if (!response.ok == true) {
				throw new Error('Bad status code from server.');
			}

			return response.json();
		})
		.then(function(responseData) {
			console.log(responseData)
				// if (!(responseData.data && responseData.data.success)) {
				// throw new Error('Bad response from server.');
				// }
		});
}
