import { Controller } from "../../node_modules/@hotwired/stimulus"
const Quill = require('quill');

// import Toolbar from 'quill/modules/toolbar';


import MagicUrl from 'quill-magic-url';
import "quill-mention";

Quill.register({
  // 'modules/toolbar': Toolbar,
  'modules/magicUrl': MagicUrl
    // 'themes/snow': Snow,
    // 'formats/bold': Bold,
    // 'formats/italic': Italic,
    // 'formats/header': Header
});

export default class extends Controller {
  static targets = ["editor", "pasteArea"]
  static values = {
    theme: { type: String, default: 'bubble' },
    toolbar: { type: String, default: 'default' },
    placeholder: { type: String, default: 'začni psát..' },
    height: { type: Number, default: 8 }
  }

  connect() {
    let toolbarOptions = this._set_toolbarOptions()
    let icons = this._set_icons()

    if (this.hasEditorTarget && this.editorTarget.querySelector('.ql-editor') == null) {
      var quill = new Quill(this.editorTarget, {
        theme: this.themeValue,
        placeholder: this.placeholderValue,
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
          magicUrl: true,
          mention: {
            allowedChars: /^[A-Za-z\sáú]*$/,
            mentionDenotationChars: ["@"],
            source: function(searchTerm, renderList, mentionChar) {
              fetch(`/group/member-list?query=${searchTerm}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json,charset=UTF-8' }
              }).then((response) => {
                return response.json();
              }).then((response) => {
                renderList(response)
              }).catch((error) => {
                console.error('Error:', error);
              });
            }
          }
        },
      });

      this.editorField = this.editorTarget.querySelector('.ql-editor')
      this.editorField.dataset.action = `focus->enable-editor#hidePlaceholder
                                         blur->enable-editor#showPlaceholder
                                         blur->enable-editor#updateArea`

      this.editorField.innerHTML = this.pasteAreaTarget.value

      if (this.themeValue == "bubble") {
        this.editorField.classList.add("form-control", "rounded-field")
      }

      if (this.heightValue > 0) {
        let style = 'min-height: ' + this.heightValue + 'rem;'
        this.editorField.style.cssText += style
      }

      this.pasteAreaTarget.classList.add('d-none');
      let labelElement = document.querySelector("label[for=" + this.pasteAreaTarget.id + "]")
      if (labelElement) {
        labelElement.classList.add('d-none');
      }
    }
  }

  updateArea(e) {
    this.pasteAreaTarget.innerHTML = this.editorField.innerHTML;
  }

  hidePlaceholder(e) {
    this.editorField.dataset.old_placeholder = this.editorField.dataset.placeholder
    this.editorField.dataset.placeholder = ""
  }

  showPlaceholder(e) {
    this.editorField.dataset.placeholder = this.editorField.dataset.old_placeholder
  }

  _set_toolbarOptions() {
    let toolbarOptions = []

    if (this.toolbarValue == "default") {
      toolbarOptions = [
        ['bold', 'italic', 'underline'], // toggled buttons
        ['link'],
        [{ 'header': 4 }, { 'header': 5 }], // custom button values
        // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ]
    } else if (this.toolbarValue == "none") {
      toolbarOptions = null
    }

    return toolbarOptions
  }

  _set_icons() {
    let icons = Quill.import('ui/icons');
    icons['header']['4'] = icons['header']['1'];
    icons['header']['5'] = icons['header']['2'];

    return icons
  }

}
